import * as API from '@/api/index'

export default {
    //项目列表
    skinCamera:params =>{
        return API.POST('api/skinCamera/all',params)
    },
    //创建检测项目
    create:params =>{
        return API.POST('api/skinCamera/create',params)
    },
    // 编辑检测项目
    update:params =>{
        return API.POST('api/skinCamera/update',params)
    },
    // 仪器编号
    skinInstrument:params =>{
        return API.POST("api/skinInstrument/list",params)
    },
    // 门店编号
    Entity:params=>{
        return API.POST("api/purchaseStorage/entity",params)
    }

}